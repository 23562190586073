body {
    font-family: 'Montserrat', sans-serif;
    overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5 {
    font-weight: 700;
}

.bg-danger {
    background-color: #c3392a !important;
}

.bg-dark {
    background-color: #1d4350 !important;
}

.bg-pattern {
    background-color: #c3392a;
    background-image: url("data:image/svg+xml,%3Csvg width='52' height='26' viewBox='0 0 52 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23ea4534' fill-opacity='0.4'%3E%3Cpath d='M10 10c0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6h2c0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4v2c-3.314 0-6-2.686-6-6 0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6zm25.464-1.95l8.486 8.486-1.414 1.414-8.486-8.486 1.414-1.414z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.btn-danger {
    background-color: #c3392a;
    color: #fff;
    border-color: #d63e2d;
}

.btn-danger:hover {
    background-color: #ad3224;
    color: #fff;
    border-color: #d63e2d;
}

.btn-danger:active {
    background-color: #ad3224;
    color: #fff;
    border-color: #d63e2d;
}

.btn-danger:focus {
    background-color: #ad3224;
    color: #fff;
    border-color: #d63e2d;
}

.btn-danger.dropdown-toggle:focus {
    background-color: #ad3224;
    color: #fff;
    border-color: #d63e2d;
}

.btn-dark {
    background-color: #1D4350;
    color: #fff;
    border-color: #214d5c;
}

.btn-dark:hover {
    background-color: #16333d;
    color: #fff;
    border-color: #214d5c;
}


.text-danger {
    color: #c3392a !important;
}

.btn {
    border-radius: 1.5rem !important;
}

.p-main {
    padding-top: 10vh;
    padding-left: 20vh;
    padding-right: 20vh;
    padding-bottom: 10vh;
}

.p-mid{
    padding-top: 15vh;
    padding-left: 20vh;
    padding-right: 20vh;
    padding-bottom: 15vh;
}

.p-cont {
    padding-top: 20vh;
    padding-left: 20vh;
    padding-right: 20vh;
    padding-bottom: 20vh;
}

.p-home {
    padding-top: 35vh;
    padding-left: 20vh;
    padding-right: 20vh;
}

.p-loading {
    padding: 35vh 3vh;
}

.p-last {
    padding-top: 10vh;
    padding-left: 0vh;
    padding-right: 0vh;
}

.border-box {
    width: 70px;
    height: 4px;
    border-radius: 2rem;
    background-color: #d63e2d;
}

#home {
    height: 90vh;
}

.img-main{
    position: absolute;
    top: -110px;
    width: 100%;
    left: 0;
    z-index: -1;
}

#loading{
    min-height: 100vh;
}

.social {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 999999;
}

.btn-circle {
    width: 50px;
    height: 50px;
    padding: 13px 15px;
    font-size: 18px;
    line-height: 1.33;
    border-radius: 25px;
}

#social.btn.btn-rojo.focus,
.btn:focus {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
}

#social.dropup .dropdown-toggle::after {
    display: none;
}

.social-l {
    list-style-type: none;
}

.loading-app-h {
    width: 100%;
    height: 40vh;
    transition: all 1s;
}


/* contacto inputs */

.bg-error {
    background-color: #ea4534 !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.form-control:focus {
    border-color: transparent;
    box-shadow: none;
}

.error input:invalid {
    background-color: #ea4534 !important;
    border-color: #ea4534 !important;
    color: rgba(255, 255, 255, 0.8) !important;
}


.error-text.no-mostrar {
    display: none;
}

.error-text.mostrar {
    display: block;
}

.error input:invalid {
    background-color: #ea4534 !important;
    border-color: #ea4534 !important;
    color: rgba(255, 255, 255, 0.8) !important;
}

.error input::placeholder {
    color: rgba(255, 255, 255, 0.8) !important;
}

.validado {
    opacity: 0;
    right: 25px;
    top: 7px;
    position: absolute;
    transition: all 0.5s;
}

.error input:required:focus:valid>.validado {
    opacity: 1;
}

.error input:invalid:focus:invalid>.validado {
    opacity: 0;
    top: 0px;
}

input {
    border-color: #214d5c !important;
    background-color: #1D4350 !important;
    color: rgba(255, 255, 255, 0.8) !important;
    transition: all 0.6s;
}

input::placeholder {
    color: rgba(216, 213, 213, 0.6) !important;
}


textarea::placeholder {
    color: rgba(255, 255, 255, 0.6) !important;
}

textarea {
    border-color: #214d5c !important;
    background-color: #1D4350 !important;
    color: rgba(255, 255, 255, 0.8) !important;
    transition: all 0.6s;
}

.error textarea:invalid {
    background-color: #ea4534 !important;
    border-color: #ea4534 !important;
    color: rgba(255, 255, 255, 0.8) !important;
}

.error textarea::placeholder {
    color: rgba(255, 255, 255, 0.8) !important;
}

.spinner-border {
    width: 1.5rem;
    height: 1.5rem;
}

.img-cont {
    height: 400px;
}



/* PAGINACION */

.pagination li {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
}

.page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: #c3392a;
    border-color: #d63e2d;
}

.page-link {
    position: relative;
    display: block;
    padding: .5rem .75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #ffffff;
    background-color: #1d4350;
    border: 1px solid #214d5c;

}

.page-link:hover {
    position: relative;
    display: block;
    padding: .5rem .75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #ffffff;
    background-color: #16333d;
    border-color: #214d5c;
}

.page-link:focus {
    z-index: 2;
    outline: 0;
}

/* PAGINACION */

/* PORTFOLIO */

.img-portfolio {
    object-fit: cover !important;
    object-position: center;
    width: 100%;
    height: 300px;
}

.color {
    height: 20px;
    width: 20px;
    border-radius: 50%;
}

.img-thumb {
    object-fit: cover !important;
    object-position: center;
    width: 140px;
    height: 140px;
}

/* PORTFOLIO */

/* ANIMACIONES */
section{
    animation: fadein 1s;
    -moz-animation: fadein 1s; /* Firefox */
    -webkit-animation: fadein 1s; /* Safari and Chrome */
    -o-animation: fadein 1s; /* Opera */

}

.atencion {
    animation: atencion 8s ease infinite;
}

@keyframes atencion {
    9% {
        transform: none;
    }

    12% {
        transform: scale(1.05);
    }

    16% {
        transform: scale(0.95);
    }

    20% {
        transform: scale(1.03);
    }

    24% {
        transform: scale(1);
    }
}

.carg {
	-webkit-animation: heartbeat 1.5s ease-in-out infinite both;
	        animation: heartbeat 1.5s ease-in-out infinite both;
}

@-webkit-keyframes heartbeat {
    from {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    10% {
      -webkit-transform: scale(0.91);
              transform: scale(0.91);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    17% {
      -webkit-transform: scale(0.98);
              transform: scale(0.98);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    33% {
      -webkit-transform: scale(0.87);
              transform: scale(0.87);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    45% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
  }
  @keyframes heartbeat {
    from {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    10% {
      -webkit-transform: scale(0.91);
              transform: scale(0.91);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    17% {
      -webkit-transform: scale(0.98);
              transform: scale(0.98);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    33% {
      -webkit-transform: scale(0.87);
              transform: scale(0.87);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    45% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
  }

  @keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-moz-keyframes fadein { /* Firefox */
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-o-keyframes fadein { /* Opera */
    from {
        opacity:0;
    }
    to {
        opacity: 1;
    }
}

  
/* ANIMACIONES */



@media only screen and (max-width: 1024px) {
    .display-4 {
        font-size: 3rem;
    }

    .img-cont {
        height: 300px;
    }

    .p-home {
        padding-top: 20vh;
        padding-left: 3vh;
        padding-right: 3vh;
    }

    .p-cont {
        padding-top: 20vh;
        padding-left: 3vh;
        padding-right: 3vh;
        padding-bottom: 20vh;
    }

    .p-main {
        padding-top: 15vh;
        padding-left: 3vh;
        padding-right: 3vh;
        padding-bottom: 15vh;
    }

    .p-mid{
        padding-top: 15vh;
        padding-left: 3vh;
        padding-right: 3vh;
        padding-bottom: 0vh;
    }

    .p-last {
        padding-top: 10vh;
        padding-left: 0vh;
        padding-right: 0vh;
    }

    .img-main{
        position: absolute;
        top: -85px;
        width: 100%;
        left: 0;
        z-index: -1;
    }
}

@media only screen and (max-width: 320px) {

    .display-4 {
        font-size: 2.5rem;
    }

    .img-cont {
        height: 250px;
    }

    h4 {
        font-size: 1.3rem;
    }

    .p-home {
        padding-top: 25vh;
        padding-left: 3vh;
        padding-right: 3vh;
    }

    .p-cont {
        padding-top: 20vh;
        padding-left: 3vh;
        padding-right: 3vh;
        padding-bottom: 20vh;
    }

    .p-mid{
        padding-top: 30vh;
        padding-left: 3vh;
        padding-right: 3vh;
        padding-bottom: 0vh;
    }

    .p-main {
        padding-top: 10vh;
        padding-left: 3vh;
        padding-right: 3vh;
        padding-bottom: 10vh;
    }


}